<template>
  <h1 class="shop__title">Shop</h1>
  <p class="shop__description">We are one of the highest quality stores on the market, with our cheap prices, fast
    support, and sweet delivery time. Shop Now!</p>
  <div class="row">
    <div class="col-lg-2">
      <div class="shop__categories">
        <!-- Add a button to show all products -->
        <div class="shop__category" :class="{ active: activeCategory === null }" @click="showAllProducts">
          All Products
        </div>
        <div v-for="category in categories" :key="category.uniqid" class="shop__category"
          :class="{ active: category.uniqid === activeCategory }" @click="setActiveCategory(category.uniqid)"
          :data-category-index="category.uniqid">
          {{ category.title }}
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="shop_category_input_box">
        <input type="text" class="shop__category_input" v-model="searchQuery" placeholder="Search a product..." />
      </div>
    </div>
  </div>
  <div class="shop__cards">
    <div v-for="product in filteredProducts" :key="product.uniqid">
      <div class="shop__card">
        <img class="shop__card__image" :src="getProductImage(product.cloudflare_image_id)" />
        <div class="shop__card__text__section">
          <h2 class="shop__card__title">{{ product.title }}</h2>
          <!--<p class="shop__card__description">{{ product.description }}</p>-->
          <p class="shop__card__stock"><span v-if="product.stock > 0" style="color: green;">In Stock</span>
          <span v-else-if="product.stock < 0" style="color: lightblue">Unlimited Stock</span>
        <span v-else style="color: red;">Out of stock</span></p>
          <div :data-sellix-product="product.uniqid" type="submit" alt="Buy Now with sellix.io"
            class="shop__card__purchase">
            <svg class="shop__card__icon" xmlns="http://www.w3.org/2000/svg" width="16.586" height="17.624"
              viewBox="0 0 15.586 16.624">
              <g id="shopping-bag" transform="translate(1 1)">
                <path id="Path_3" data-name="Path 3"
                  d="M5.194,2,3,4.925V15.161a1.462,1.462,0,0,0,1.462,1.462H14.7a1.462,1.462,0,0,0,1.462-1.462V4.925L13.968,2Z"
                  transform="translate(-2.575 -2)" fill="none" stroke="#cf9816" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="2" />
                <line id="Line_2" data-name="Line 2" x2="13.586" transform="translate(0 2.736)" fill="none"
                  stroke="#cf9816" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_4" data-name="Path 4" d="M13.849,10A2.925,2.925,0,0,1,8,10"
                  transform="translate(-3.92 -4.151)" fill="none" stroke="#cf9816" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="2" />
              </g>
            </svg>
            Purchase
            <p class="shop__card__price"><span v-if="product.price_discount > 0" class="price-container">
              <s class="original-price">{{ product.price }} {{ product.currency }}</s>
              <span class="discounted-price">{{ discountedPrice(product) }} {{ product.currency }}</span> <!-- Calculated discounted price -->
            </span>
            <span v-else class="discounted-price">
              {{ product.price }} {{ product.currency }} <!-- Regular price without discount -->
            </span>
          </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],        // List of all products
      searchQuery: '',     // Search query entered by the user
      categories: [],      // List of all categories
      activeCategory: null // Currently active category
    };
  },
  computed: {
    filteredProducts() {
      let filtered = this.products;

      // Filter products by active category
      if (this.activeCategory) {
        filtered = filtered.filter(product =>
          product.categories.some(cat => cat.uniqid === this.activeCategory)
        );
      }

      // Further filter by search query
      if (this.searchQuery) {
        filtered = filtered.filter(product =>
          product.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      return filtered;
    }
  },
  mounted() {
    this.fetchProducts();   // Fetch products when the component is mounted
    this.fetchCategories(); // Fetch categories when the component is mounted
  },
  methods: {
    fetchProducts() {
      const apiKey = 'Mb4bFUWjIZyjaKHLQYtNRUBmMOXFvoyCMIqvHlqjISBhrT7B3k6N6TndaJ0HEeuM';
      const apiUrl = 'https://dev.sellix.io/v1/products';

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data && data.data && data.data.products) {
            this.products = data.data.products; // Adjust to access products correctly
          } else {
            console.error('Neispravan format podataka:', data);
          }
        })
        .catch(error => {
          console.error('Greška pri dohvaćanju proizvoda:', error);
        });
    },
    discountedPrice(product) {
      const discountAmount = product.price * (product.price_discount / 100);
      return (product.price - discountAmount).toFixed(2); // Calculate and format the discounted price
    },
    getProductImage(cloudflareImageId) {
      const baseUrl = 'https://imagedelivery.net/95QNzrEeP7RU5l5WdbyrKw/';
      if (cloudflareImageId) {
        return `${baseUrl}${cloudflareImageId}/public`; // URL with image variant
      } else {
        // Return a placeholder image or empty string if no image
        return './assets/img/no-image.png'; // Replace with your placeholder URL
      }
    },
    fetchCategories() {
      const apiKey = 'Mb4bFUWjIZyjaKHLQYtNRUBmMOXFvoyCMIqvHlqjISBhrT7B3k6N6TndaJ0HEeuM';
      const apiUrl = 'https://dev.sellix.io/v1/categories';

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data && data.data && data.data.categories) {
            this.categories = data.data.categories; // Adjust to access products correctly
          } else {
            console.error('Neispravan format podataka:', data);
          }
        })
        .catch(error => {
          console.error('Greška pri dohvaćanju proizvoda:', error);
        });
    },
    setActiveCategory(categoryId) {
      this.activeCategory = categoryId; // Set the active category when a category is clicked
    },
    showAllProducts() {
      this.activeCategory = null; // Show all products
    }
  },
  created() {
    this.fetchProducts(); // Fetch products when the component is created
    this.fetchCategories();
  }
};
</script>
